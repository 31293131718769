import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AdminDashboard.css';
import moment from 'moment/moment';


const AdminDashboard = () => {

  const [data, setData] = useState([]);
  const [pending, setPending] = useState();
  const [solved, setSolved] = useState();
  const [threedays, setThreeDays] = useState();

  useEffect(() => {
    getCount();
  }, [])

  const getCount = async () => {
    const res = await fetch("https://server.archanatusharpatil.in/complaintlist", {
      // const res = await fetch("http://localhost:5000/complaintlist",{
      headers: {
        authorization: JSON.parse(localStorage.getItem("token"))
      }
    })
    const result = await res.json();
    setData(result);
    setPending((result.filter((item) => item.status == "Pending")));
    setSolved((result.filter((item) => item.status == "solve")));
    let today = new Date();
    let priorDate = moment(new Date(new Date().setDate(today.getDate() - 3))).format("YYYY-MM-DD  hh:mm:ss");
    setThreeDays((result.filter((item) =>  item.date <= priorDate && item.status == "Pending")))
  }

  return (
    <>
      <div className='nav-counter'>
          <button className='count'>Total Complaints : {data?.length}</button>
          <button className='count'>Total Pending : {pending?.length}</button>
          <button className='count'>Total Solved : {solved?.length}</button>
          <button className='count'>3 Dasys Old : {threedays?.length}</button>
      </div>
      <hr />
    </>
  )
}

export default AdminDashboard